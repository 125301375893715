<script>
import Layout from "../../layouts/main";
import axios from 'axios';

import base_domain from '../../myvars'
axios.defaults.baseURL = base_domain

export default {

  components: {
    Layout
  },


  data() {
    return {
      not_count:0,
      not_loop:[],
    };
  },
  mounted() {
        this.fetchNotifData();
    },
  methods: {
    fetchNotifData() {
        var tken = localStorage.getItem("accessToken");
        console.log('first step with token ----- ',tken)
        axios
          .post(`/applications/kick_notif/`, {
            token: tken,read:true
          })
            .then(response => {
                const data = response.data;
                console.log('notification data>>>>>...........', data)
                this.not_count            = data[1]
                this.not_loop             = data[0]
                // this.full_name          = data.user_data.section_a1.first_name,
                })
            .catch(error => {
            console.error(error);
            });
        },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    }
  },
  

};
</script>

<template>
  <Layout>
    <main class="py-4">
        <div class="row mb-3">
            <div class="col">
                <h2 class="font-size-22 text-theme-dark">Notifications</h2>
            </div>
        </div>
        <div class="notify" v-for="i in not_loop" :key="i">
            <div class="card mb-1">
                <div class="card-body p-3">
                    <div class="notification-card">
                        <h6 v-if="i[3]">{{i[0]}}</h6>
                        <h6 class="fw-bold" v-else>{{i[0]}}</h6>
                        <p v-if="i[3]">{{i[1]}}</p>
                        <p class="fw-bold" v-else>{{i[1]}}</p>
                        <span class="dateTimeStyle text-muted d-flex">
                            <i class="mdi mdi-calendar-clock me-2"></i>{{i[2]}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </main>
  </Layout>
</template>